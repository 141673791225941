import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import logo from '../../content/assets/logo.svg'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import SEO from './seo'

interface Props extends GatsbyProps {}

interface LIProps {
    path: string
    name: string
    sublinks: JSX.Element[]
    left: boolean
    style: React.CSSProperties
}

const LI: (props: LIProps) => JSX.Element = ({ path, name, sublinks, left, style }) => {
    const [hovering, setHovering] = useState(false)

    return (
        <li
            style={style}
            onMouseOver={() => setHovering(true)}
            onMouseOut={() => setHovering(false)}
            className="relative m-0 border-secondary"
        >
            <Link to={path}>
                <div
                    className={`h-full flex items-end py-2 px-3 border-b-2 border-transparent ${
                        hovering ? 'border-red-500 bg-secondary bg-opacity-25' : ''
                    }`}
                >
                    {sublinks && (left ? '◂ ' : '▾ ')}
                    {name}
                </div>
            </Link>
            {sublinks && (
                <ul
                    className={`shadow-md rounded-sm absolute flex flex-col m-0 bg-white border-2 normal-case ${
                        left ? 'left-0 top-0' : ''
                    } ${hovering ? '' : 'hidden'}`}
                    style={{
                        transform: left ? 'translate(-100%, -2px)' : ''
                    }}
                >
                    {sublinks}
                </ul>
            )}
        </li>
    )
}

const Layout: (props: Props) => JSX.Element = ({ location, children }) => {
    const {
        allMdx: { edges: pages },
        site: {
            siteMetadata: { title: siteTitle }
        }
    } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                    }
                }
                allMdx(sort: { fields: [frontmatter___title], order: ASC }) {
                    edges {
                        node {
                            excerpt
                            fields {
                                slug
                            }
                            frontmatter {
                                title
                                # date(formatString: "MMMM DD, YYYY")
                                # description
                            }
                        }
                    }
                }
            }
        `
    )

    const pageTree = {}

    for (const page of pages) {
        const data = page.node
        let start = pageTree
        data.fields.slug
            .split('/')
            .filter(part => part.length)
            .forEach(part => {
                if (start[part] === undefined) {
                    start[part] = {}
                }
                start = start[part]
            })
    }

    const pagesData = {}

    for (const page of pages) {
        const data = page.node
        pagesData[data.fields.slug.replace(/\/$/g, '')] = data
    }

    const partToTitle = (str: string) => {
        return str.replace('-', ' ').replace(/^./, char => char.toUpperCase())
    }

    const genCrumbs = () => {
        const crumbs = []
        const parts = location.pathname.split('/').filter(part => part.length)
        let prefix = ''
        for (const part of parts) {
            const path = `${prefix}/${part}`
            const page = pagesData[path]

            crumbs.push({
                pathname: path,
                crumbLabel: page ? page.frontmatter.title : partToTitle(part)
            })

            prefix = path
        }

        if (crumbs.length === 0) return [{ pathname: '/', crumbLabel: 'Home' }]

        return crumbs
    }

    const crumbs = genCrumbs()

    let level = 0
    const genLinks = (keyPrefix: string, prefix = '', start = pageTree) => {
        level++
        const links = []
        const left = level > 1
        for (const part in start) {
            const path = `${prefix}/${part}`
            const page = pagesData[path]
            const hasSubpaths = Object.keys(start[part]).length !== 0
            let subLinks
            if (hasSubpaths) {
                subLinks = genLinks(keyPrefix, path, start[part])
            }
            if (page !== undefined) {
                links.push(getLink(keyPrefix, path, page.frontmatter.title, subLinks, left))
            }
        }
        return links
    }

    const getLink = (
        keyPrefix: string,
        path: string,
        name: string,
        sublinks?: JSX.Element[],
        left?: boolean,
        style?: React.CSSProperties
    ) => (
        <LI
            key={`${keyPrefix}${path}`}
            path={path}
            name={name}
            sublinks={sublinks}
            left={left}
            style={style}
        />
    )

    const lis = []
    const t = (pageTree, prefix = '') => {
        for (const pagePart in pageTree) {
            const path = `${prefix}/${pagePart}`
            const page = pagesData[path]
            const hasSubpaths = Object.keys(pageTree[pagePart]).length !== 0
            const level = (path.split('/').filter(s => s).length - 1) * 8
            const l = getLink('2', path, page.frontmatter.title, undefined, undefined, {
                borderLeftWidth: level + 'px'
            })
            lis.push(l)
            if (hasSubpaths) t(pageTree[pagePart], path)
        }
    }
    t(pageTree)

    // const lis = pages.map(
    //     ({
    //         node: {
    //             fields: { slug },
    //             frontmatter: { title }
    //         }
    //     }) => {
    //         const path = slug.replace(/\/$/g, '')
    //         const level = (path.split('/').filter(s => s).length - 1) * 8
    //         return getLink('2', path, title)
    //     }
    // )

    const [isOpen, setIsOpen] = React.useState(false)

    return (
        <div className="flex flex-col min-h-screen">
            <SEO title={crumbs[crumbs.length - 1].crumbLabel} />
            <header className="h-12 border-b-2 border-secondary bg-white sticky top-0 z-50 shadow-sm">
                <div className="h-full container mx-auto">
                    <div className="h-full flex items-stretch">
                        <div className="h-full p-2">
                            <Link className="h-full" to="/">
                                <img className="h-full m-0" src={logo} alt="Logo" />
                            </Link>
                        </div>
                        <div className="flex-grow"></div>
                        <nav className="hidden md:block">
                            <ul className="flex h-full m-0 whitespace-nowrap uppercase text-xl leading-tight">
                                {getLink('1', '/', 'Home')}
                                {getLink('1', '/despre-noi', 'Despre noi')}
                                {genLinks('1')}
                                {getLink('1', '/contact', 'Contact')}
                            </ul>
                        </nav>
                        <button
                            type="button"
                            className="block md:hidden p-2"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <svg
                                className="h-6 w-6 fill-current"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                            >
                                {isOpen && (
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                                    />
                                )}
                                {!isOpen && (
                                    <path
                                        fillRule="evenodd"
                                        d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                                    />
                                )}
                            </svg>
                        </button>
                    </div>
                </div>
            </header>
            <aside
                className={`pt-12 transform top-0 right-0 w-64 bg-white fixed h-full overflow-x-hidden overflow-y-auto ease-in-out transition-all duration-300 z-40 shadow-md ${
                    isOpen ? 'translate-x-0' : 'translate-x-full'
                }`}
            >
                <ul className="flex flex-col m-0 text-lg leading-tight">
                    {getLink('2', '/', 'Home')}
                    {getLink('2', '/despre-noi', 'Despre noi')}
                    {lis}
                    {getLink('2', '/contact', 'Contact')}
                </ul>
            </aside>
            <div
                onClick={() => setIsOpen(false)}
                style={{ width: `calc(100% - 16rem)` }}
                className={`transform top-0 left-0 bg-black bg-opacity-25 fixed h-full overflow-hidden ease-in-out transition-all duration-300 z-30 ${
                    isOpen ? 'translate-x-0' : '-translate-x-full'
                }`}
            ></div>
            {crumbs.length > 1 && (
                <div className="container mx-auto px-4 py-1">
                    <Breadcrumb crumbs={crumbs} crumbSeparator=" ❯ " title="❯❯ " />
                </div>
            )}
            <div className="flex-grow">{children}</div>
            <footer className="p-4 border-t-2 shadow-2xl">
                <div className="container mx-auto">
                    © {new Date().getFullYear()} {siteTitle}
                </div>
            </footer>
        </div>
    )
}

export default Layout
